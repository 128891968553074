import React, { useEffect } from "react";
import moment from "moment";

import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, Typography, TableContainer } from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead } from "../../sections/@dashboard/user";
import { listAllPagePayments, resetPagePaymentsData } from "store/actions";

const TABLE_HEAD = [
    { id: "payment_id", label: "Payment ID", alignRight: false },
    { id: "amount", label: "Amount", alignRight: false },
    { id: "chain_network", label: "Network", alignRight: false },
    // { id: "user_email", label: "User Email", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "created_at", label: "Created At", alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

export default function PagePayments({ pageId, dispatch, useSelector }) {
    // const [selected, setSelected] = useState([]);

    const { paymentPagesData, showViewMore } = useSelector(
        ({
            paymentPages: {
                pagePayments: { list, showViewMore },
            },
        }) => ({
            paymentPagesData: list,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetPagePaymentsData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        if (pageId) {
            dispatch(listAllPagePayments({ pageId: pageId }));
        }
    };

    const filteredUsers = paymentPagesData;

    const isUserNotFound = paymentPagesData.length === 0;

    return (
        <Card>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead headLabel={TABLE_HEAD} rowCount={paymentPagesData.length} />
                        <TableBody>
                            {filteredUsers.map((row, index) => {
                                const { payment_id, status, amount, chain_network, created_at, currency } = row;
                                // const isItemSelected = selected.indexOf(url) !== -1;

                                return (
                                    <TableRow
                                        hover
                                        key={index}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        // onClick={() => navigate(`/dashboard/page-details/${page_id}`)}
                                    >
                                        <TableCell padding="checkbox">
                                            {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, url)} /> */}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography noWrap>{payment_id}</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography noWrap>
                                                {amount} {currency ? currency.toUpperCase() : ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            {chain_network === "eth_usdc" ? "ethereum" : chain_network}
                                        </TableCell>
                                        {/* <TableCell align="left">{user_id?.email ?? "-"}</TableCell> */}
                                        <TableCell align="left">{status}</TableCell>
                                        <TableCell align="left">{moment.unix(created_at).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right"> {/* <UserMoreMenu id={plink_id}/> */} </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={" "} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
            {showViewMore && (
                <Stack justifyContent="center">
                    <Button onClick={fetchMoreData}>Show more</Button>
                </Stack>
            )}
        </Card>
    );
}
