export const CREATE_NEW_PAYMENT_PAGE = `mutation ($title: String!, $contactUsEmail: String!, $expiry: Int, $amountType: String, $amount: Float, $network: String, $fields: [create_payment_page_fields]) {
  create_payment_page(
    title: $title,
    contact_us_email: $contactUsEmail,
    expiry: $expiry,
    amount_type: $amountType,
    amount: $amount,
    network: $network,
    fields: $fields
  ){
    message
    url
  }
}
`;

export const FETCH_ALL_PAYMENT_PAGES = `query ($status: String, $pageSize: Int, $pageNumber: Int) {
  list_all_payment_pages(page_size: $pageSize, page_number: $pageNumber, status: $status){
    message
    data {
      _id
      page_id
      title
      url
      amount
      amount_type
      currency
      created_at
      status
      expiry
    }
    hasMore
  }
}`;

export const FETCH_PAYMENT_PAGE_DETAILS = `query($pageId:String!){
  list_payment_page_details(page_id:$pageId){
    message
    data {
      _id
      page_id
      title
      amount
      amount_type
      currency
      created_at
      status
      expiry
      url
      chain_network
      contact_us_email
      fields {
        name
        value
      }
    }
  }
}

`;

export const FETCH_ALL_PAGE_PAYMENTS = `query($pageId:String!,$pageNumber:Int,$pageSize:Int,$status:String){
  list_all_page_payments(page_id:$pageId,page_size:$pageSize,page_number:$pageNumber,status:$status){
    message
    data {
      _id
      payment_id
      amount
      currency
      chain_network
      created_at
      status
      txn_id {
        txn_id
        txn_signature
        status
      }
    }
    hasMore
  }
}

`;

export const SHARE_PAGE_LINK_TO_EMAIL = `query ($pageId: String!, $email: String!, $name: String) {
  share_page_link_to_email(page_id: $pageId, email: $email, name: $name) {
    message
  }
}
`;
